
import axios from 'axios'
import router from "@/router/index";
import Swal from 'sweetalert2';

/***All request store */
/** CAC ADDRESSES */
export const fetchtDetailsStore = async (context) => {
  const _GET = process.env.VUE_APP_CUSTOMER_BASE_URL + "stores/" + router.currentRoute.value.params.UserUUID;
  try {
    const response = await axios.get(_GET, {
      method: "GET",
    });
    if (response?.status) {
      //console.log("Store data ")
      //console.log(response?.data?.data)
      context.commit('SET_DETAILS_STORE', response?.data?.data)
    }
  } catch (error) {
    //console.log(error)
  }
}

export const fetchProducts = async (context) => {
  const _GET = process.env.VUE_APP_CUSTOMER_BASE_URL + "products/" + router.currentRoute.value.params.UserUUID;
  try {
    const response = await axios.get(_GET, {
      method: "GET",
    });
    if (response?.status) {
      //console.log("pro data ")
      //console.log(response?.data?.data)
      context.commit('SET_CATAGORIES', response?.data?.data)
    }
  } catch (error) {
    //console.log(error)
  }
}

export const getCacAddresses = async (context) => {
  //console.log(process.env.VUE_APP_CUSTOMER_BASE_URL)
  const _GET = process.env.VUE_APP_CUSTOMER_BASE_URL + "cac_addresses/" + router.currentRoute.value.params.UserUUID;
  try {
    const response = await axios.get(_GET, {
      method: "GET",
    });
    if (response?.status) {
      context.commit('SET_CAC_ADDRESSE', response?.data?.data)
    }
  } catch (error) { }
}
export const checkCouponCode = async (context, payload) => {
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-right',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true
  })
  const _GET = process.env.VUE_APP_CUSTOMER_BASE_URL + "promocode/code/" + payload.coupon_code + '/' + router.currentRoute.value.params.UserUUID + '/' + payload.subTotal;
  try {
    const response = await axios.get(_GET, {
      method: "GET",
    });
    //console.log(response)
    if (response?.status) {
      context.commit('SET_COUPON_CODE', response?.data?.data)
      Toast.fire({
        icon: 'success',
        title: 'Succès',

      });
    }
  } catch (error) {
    Toast.fire({
      icon: 'error',
      title: 'Code promo introuvable',

    });
  }
}

export const getTables = async (context, payload) => {
  const _GET = process.env.VUE_APP_CUSTOMER_BASE_URL + "tables/" + router.currentRoute.value.params.UserUUID;
  try {
    // Perform the API request or fetch the data
    const response = await axios.get(_GET, {
      method: "GET",
    });
    context.commit('SET_LIST_TABLES', response?.data?.data)
  } catch (error) {
    //console.error("Failed to fetch store data:", error);
  }
}

/**end request store */

export const getLocalPanier = async (context) => {
  const cart = localStorage.getItem("cart") || {}
  if (Object.keys(cart).length > 0)
    context.commit('CART_STRORE', JSON.parse(cart))
}


// YES
export const createOrder = async (context, payload) => {
  const _POST = process.env.VUE_APP_BORNE_BASE_URL + "orders"


  try {
    const response = await axios.post(_POST, payload, {
      method: "POST",
      // headers: context.state.userStore.headers,
    });
    if (response.status == 201) {
      localStorage.removeItem("cart");
      context.commit('ORDER_CREATED', router.currentRoute.value.params.UserUUID)
      router.push({ name: 'orders', params: { UserUUID: router.currentRoute.value.params.UserUUID } })
    }
  } catch (error) {
    //console.log(error);
  } finally { }
}




import authHeader from '@/services/auth-header';

export const getCustomersStore = async (context) => {
  const _GET = process.env.VUE_APP_ROOT_API + "api/customerstore/" + router.currentRoute.value.params.UserUUID;
  try {
    const response = await axios.get(_GET, { headers: authHeader() });
    if (response?.status) {
      console.log(response?.data?.data)
      context.commit('SET_CUSTOMERS_STORE', response?.data?.data)
    }
  } catch (error) { }
}
